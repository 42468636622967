<template>
  <div class="hero-main platinum-layout white-sec" :style="style">
    <div id="not-found-page">
      <div class="container">
        <div class="row justify-content-center align-items-center text-center">
          <div class="col-xl-7 col-lg-9">
            <figure>
              <img
                src="@/assets/svg/404.svg"
                alt=""
                class="img-fluid"
                width="550"
                height="234"
              >
            </figure>
            <p>Page Not Found</p>
            <div class="text-center">
              <router-link class="btn el-btn mt-4 px-5" to="/">Back</router-link>
            </div>
          </div>
        </div>
        <!-- /row -->
      </div>
      <!-- /container -->
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import backgroundBanner from '@/assets/jpg/banner-1.jpg';

  export default Vue.extend({
    name: 'el-not-found',
    data: () => ({
      style: { backgroundImage: `url(${backgroundBanner})` },
    }),
  });
</script>
